import React, { useState } from "react";
import classes from './Tabs.module.scss';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

const tabs = [
    {
        title: 'trading.staking.tab_01.title',
        text: [
            'trading.staking.tab_01.text_01',
            'trading.staking.tab_01.text_02',
        ],
    },
    {
        title: 'trading.staking.tab_02.title',
        text: [
            'trading.staking.tab_02.text_01',
            'trading.staking.tab_02.text_02',
            'trading.staking.tab_02.text_03',
        ],
    },
    {
        title: 'trading.staking.tab_03.title',
        text: [
            'trading.staking.tab_03.text_01',
            'trading.staking.tab_03.text_02',
            'trading.staking.tab_03.text_03',
        ],
    },
    {
        title: 'trading.staking.tab_04.title',
        text: [
            'trading.staking.tab_04.text_01',
            'trading.staking.tab_04.text_02',
            'trading.staking.tab_04.text_03',
        ],
    },
    {
        title: 'trading.staking.tab_05.title',
        text: [
            'trading.staking.tab_05.text_01',
            'trading.staking.tab_05.text_02',
            'trading.staking.tab_05.text_03',
        ],
    },
];

const Tabs = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    return (
        <section className="container">
            <div className={classes.wrap}>
                <Fade left>
                    <h2 className={`${classes.title} font-30`}>
                        <span>
                            {t('trading.staking.title')}
                        </span>
                    </h2>
                </Fade>
                <p className={`${classes.text} font-17`}>
                    {t('trading.staking.text')}
                </p>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                        <div key={index} className={`${classes.tab} ${active === index ? classes.active : ''} font-17`}>
                            <div
                                className={`${classes.tabHeader} no-select`}
                                onClick={() => setActive(index)}
                            >
                                <h4 className={classes.tabTitle}>
                                    {t(tab.title)}
                                </h4>
                                <div className={classes.tabStatus}></div>
                            </div>
                            <div className={`${classes.tabText} txt-container`}>
                                {tab.text.map((text, index) =>
                                    <p key={index}>
                                        {t(text)}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Tabs;

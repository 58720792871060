import React from "react";
import classes from "./Footer.module.scss";
import { Trans, useTranslation } from "react-i18next";
import logo from '../../../assets/img/logo.svg'

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  return (
    <footer className={`${classes.footerWrap} mt`}>
      <div className="container">
        <div className={classes.footer}>
          <img className={classes.logo} src={logo} alt='' />
          <div className={`${classes.contacts} font-17`}>
            <a href="tel:+441294620295">
              +441294620295
            </a>
            <a href={`mailto:support@${hostname}`}>
              support@{hostname}
            </a>
          </div>
          <div className={`${classes.docs} font-17`}>
            <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
              {t('footer.docs.terms')}
            </a>
            <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
              {t('footer.docs.privacy')}
            </a>
            <a href="/docs/AML_POLICY.pdf" target="_blank">
              {t('footer.docs.aml')}
            </a>
            <a href="/docs/fca.pdf" target="_blank">
              FCA
            </a>
            <a href="/docs/finma.pdf" target="_blank">
              Finma
            </a>
          </div>
          <div className={classes.about}>
            <div className={classes.block}>
              <h6>
                {t('footer.disclaimer.title')}
              </h6>
              <p>
                <Trans>
                  {t('footer.disclaimer.text')}
                </Trans>
              </p>
            </div>
            <div className={classes.rights}>
              {t('footer.rights')}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import classes from './Tabs.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image from '../../../assets/img/InvestingPage/tab_05.jpg';

const Tab5 = ({ data }) => {
    const { t } = useTranslation();
    return (
        <div className={classes.tab_05}>
            <div className={classes.head}>
                <div className={classes.image}>
                    <img src={image} alt='' />
                </div>
                <div className={classes.headContent}>
                    <h2 className={`${classes.title} font-30`}>
                        {t(data.title)}
                    </h2>
                    <div className={`${classes.bold} font-17`}>
                        {t(data.text_01)}
                    </div>
                    <div className="mt-btn">
                        <Button />
                    </div>
                </div>
            </div>
            <div className={`${classes.mt50} ${classes.gap15} font-17`}>
                <p>
                    {t(data.text_02)}
                </p>
                <p>
                    {t(data.text_03)}
                </p>
            </div>
            <div className={`${classes.block} ${classes.mt50} font-17`}>
                <h4 className={classes.bold}>
                    {t(data.subtitle)}
                </h4>
                <p>
                    {t(data.text_04)}
                </p>
            </div>
            <h4 className={`${classes.mt50} font-30`}>
                {t(data.block_01.title)}
            </h4>
            <ul className={classes.listGrid}>
                {data.block_01.list.map((li, index) =>
                    <li key={index} className={`${classes.item} font-17`}>
                        <svg className={classes.check} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                            <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#B73D2B" />
                        </svg>
                        <p>
                            <Trans>
                                {t(li)}
                            </Trans>
                        </p>
                    </li>
                )}
            </ul>
            <h4 className={`${classes.mt50} font-30`}>
                {t(data.block_02.title)}
            </h4>
            <ul className={classes.list2}>
                {data.block_02.list.map((li, index) =>
                    <li key={index} className={classes.item}>
                        <svg className={classes.check} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                            <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#B73D2B" />
                        </svg>
                        <div className={`${classes.itemContent} font-17`}>
                            <h4 className={classes.subtitle}>
                                <b>
                                    {t(li.title)}
                                </b>
                            </h4>
                            <p className={classes.text}>
                                {t(li.text)}
                            </p>
                        </div>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default Tab5;

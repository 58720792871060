import React from "react";
import classes from './ReadyTo.module.scss';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

const cards = [
    {
        title: 'home.ready_to.card_01.title',
        text: 'home.ready_to.card_01.text',
    },
    {
        title: 'home.ready_to.card_02.title',
        text: 'home.ready_to.card_02.text',
    },
    {
        title: 'home.ready_to.card_03.title',
        text: 'home.ready_to.card_03.text',
    },
];

const ReadyTo = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-58`}>
                    {t('home.ready_to.title')}
                </h2>
                <h4 className={`${classes.subtitle} font-30`}>
                    {t('home.ready_to.subtitle')}
                </h4>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <Fade key={index} bottom>
                            <div className={classes.card}>
                                <div className={`${classes.cardNum} font-30`}>
                                    {index + 1}
                                </div>
                                <div className={`${classes.cardTitle} font-30`}>
                                    {t(card.title)}
                                </div>
                                <p className={`${classes.cardText} font-17`}>
                                    {t(card.text)}
                                </p>
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default ReadyTo;

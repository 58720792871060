import React from "react";
import classes from './Constructing.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/Constructing/image_01.avif';
import image_02 from '../../../assets/img/HomePage/Constructing/image_02.avif';
import image_03 from '../../../assets/img/HomePage/Constructing/image_03.avif';
import image_04 from '../../../assets/img/HomePage/Constructing/image_04.avif';
import image_05 from '../../../assets/img/HomePage/Constructing/image_05.avif';
import { Fade } from "react-reveal";

const TYPES = {
    title: 'title',
    text: 'text',
    image: 'image',
};

const cards = [
    [
        {
            type: TYPES.title,
            value: 'home.constructing.card_01.title',
        },
        {
            type: TYPES.text,
            value: 'home.constructing.card_01.text',
        },
        {
            type: TYPES.image,
            value: image_01,
        },
    ],
    [
        {
            type: TYPES.text,
            value: 'home.constructing.card_02.text',
        },
        {
            type: TYPES.image,
            value: image_02,
        },
        {
            type: TYPES.title,
            value: 'home.constructing.card_02.title',
        },
    ],
    [
        {
            type: TYPES.image,
            value: image_03,
        },
        {
            type: TYPES.title,
            value: 'home.constructing.card_03.title',
        },
        {
            type: TYPES.text,
            value: 'home.constructing.card_03.text',
        },
    ],
    [
        {
            type: TYPES.text,
            value: 'home.constructing.card_04.text',
        },
        {
            type: TYPES.image,
            value: image_04,
        },
        {
            type: TYPES.title,
            value: 'home.constructing.card_04.title',
        },
    ],
    [
        {
            type: TYPES.image,
            value: image_05,
        },
        {
            type: TYPES.title,
            value: 'home.constructing.card_05.title',
        },
        {
            type: TYPES.text,
            value: 'home.constructing.card_05.text',
        },
    ],
    [
        {
            type: TYPES.text,
            value: 'home.constructing.card_06.text',
        },
        {
            type: TYPES.title,
            value: 'home.constructing.card_06.title',
        },
    ],
];

const Constructing = () => {
    const { t } = useTranslation();
    return (
        <section className={`${classes.wrap} mt`}>
            <div className={classes.cards}>
                <div className={`${classes.cardWrap} ${classes.headWrap}`}>
                    <div className="container">
                        <div className={`${classes.card} ${classes.head}`}>
                            <h2 className={classes.title}>
                                <Trans>
                                    {t('home.constructing.title')}
                                </Trans>
                            </h2>
                            <p className={`${classes.text} font-18`}>
                                {t('home.constructing.text')}
                            </p>
                        </div>
                    </div>
                </div>
                {cards.map((card, index) =>
                    <Fade key={index} bottom>
                        <div className={classes.cardWrap}>
                            <div className="container">
                                <div className={classes.card}>
                                    {card.map((item, itemIndex) =>
                                        item.type === TYPES.title ?
                                            <h4 key={itemIndex} className={`${classes.title} font-90`}>
                                                {t(item.value)}
                                            </h4>
                                            :
                                            item.type === TYPES.text ?
                                                <p key={itemIndex} className={`${classes.text} font-18`}>
                                                    {t(item.value)}
                                                </p>
                                                :
                                                <img key={itemIndex} className={classes.image} src={item.value} alt='' />
                                    )}
                                </div>
                            </div>
                        </div>
                    </Fade>
                )}
            </div>
        </section>
    );
};

export default Constructing;

import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/AboutPage/Banner/Banner";
import OurValues from "../components/AboutPage/OurValues/OurValues";
import WhoWe from "../components/AboutPage/WhoWe/WhoWe";
import WhyChoose from "../components/AboutPage/WhyChoose/WhyChoose";
import Security from "../components/AboutPage/Security/Security";
import JoinCommunity from "../components/AboutPage/JoinCommunity/JoinCommunity";
import OurAwards from "../components/HomePage/OurAwards/OurAwards";
import InvestWith from "../components/AboutPage/InvestWith/InvestWith";
import Tabs from "../components/AboutPage/Tabs/Tabs";
import GoogleMap from "../components/AboutPage/GoogleMap/GoogleMap";

const AboutPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Arma LTD · About Us</title>
        <meta name="title" content="About Arma LTD - Your Trusted Partner in Financial Markets" />
        <meta property="og:title" content="About Arma LTD - Your Trusted Partner in Financial Markets" />
        <meta property="twitter:title" content="About Arma LTD - Your Trusted Partner in Financial Markets" />

        <meta name="description" content="Learn about Arma LTD, your trusted partner in the world of financial markets. Discover our mission, values, and commitment to your success." />
        <meta property="og:description" content="Learn about Arma LTD, your trusted partner in the world of financial markets. Discover our mission, values, and commitment to your success." />
        <meta property="twitter:description" content="Learn about Arma LTD, your trusted partner in the world of financial markets. Discover our mission, values, and commitment to your success." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <OurValues />
        <WhoWe />
        <InvestWith />
        <WhyChoose />
        <Security />
        <JoinCommunity />
        <Tabs />
        <OurAwards />
        <GoogleMap/>
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AboutPage;

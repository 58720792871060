import React from "react";
import classes from './Opportunities.module.scss';
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const list = [
    'trading.opportunities.li_01',
    'trading.opportunities.li_02',
    'trading.opportunities.li_03',
    'trading.opportunities.li_04',
];

const Opportunities = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-30`}>
                    {t('trading.opportunities.title')}
                </h2>
                <div className={classes.content}>
                    <div className={classes.col}>
                        <p className="font-17">
                            {t('trading.opportunities.text_01')}
                        </p>
                        <ul className={`${classes.list} font-17`}>
                            {list.map((li, index) =>
                                <li key={index} className={classes.item}>
                                    <svg className={classes.check} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                        <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#B73D2B" />
                                    </svg>
                                    <p>
                                        {t(li)}
                                    </p>
                                </li>
                            )}
                        </ul>
                        <div className={`${classes.btn} mt-btn`}>
                            <Button />
                        </div>
                    </div>
                    <div className={`${classes.col} txt-container font-17`}>
                        <p>
                            {t('trading.opportunities.text_02')}
                        </p>
                        <p>
                            {t('trading.opportunities.text_03')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Opportunities;

import React from "react";
import classes from './TakeAdvantages.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/take_advantages.avif';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const cards = [
    {
        text: 'home.take_advantages.card_01',
    },
    {
        text: 'home.take_advantages.card_02',
    },
    {
        text: 'home.take_advantages.card_03',
    },
];

const TakeAdvantages = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.head}>
                    <div className={classes.content}>
                        <h2 className={`${classes.title} font-30`}>
                            <Trans>
                                {t('home.take_advantages.title')}
                            </Trans>
                        </h2>
                        <div className={`${classes.btn} mt-btn`}>
                            <Button />
                        </div>
                    </div>
                    <Fade right>
                        <div className={classes.image}>
                            <img src={image} alt='' />
                        </div>
                    </Fade>
                </div>
                <div className={`${classes.cards} font-17`}>
                    {cards.map((card, index) =>
                        <Fade key={index} bottom>
                            <div className={classes.card}>
                                <p className={classes.text}>
                                    {t(card.text)}
                                </p>
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default TakeAdvantages;

import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/IslamicPage/banner.jpg';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h1 className={`${classes.title} font-30`}>
                        {t('trading.islamic.banner.title')}
                    </h1>
                    <div className={`${classes.text} font-17 txt-container`}>
                        <p>
                            {t('trading.islamic.banner.text_01')}
                        </p>
                        <p>
                            {t('trading.islamic.banner.text_02')}
                        </p>
                    </div>
                    <div className={`${classes.btn} mt-btn`}>
                        <Button />
                    </div>
                </div>
                <Fade right>
                    <img className={classes.image} src={image} alt='' />
                </Fade>
            </div>
        </section>
    );
};

export default Banner;

import React from "react";
import classes from './Security.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/AboutPage/Security/image_01.avif';
import image_02 from '../../../assets/img/AboutPage/Security/image_02.avif';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const Security = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={`${classes.col} ${classes.gap50}`}>
                    <div className="txt-container">
                        <h2 className="font-30">
                            {t('about.security.title')}
                        </h2>
                        <p className="font-17">
                            {t('about.security.text')}
                        </p>
                        <div className="txt-container font-17">
                            <h4 className={classes.ttl}>
                                <b>
                                    {t('about.security.block_01.title')}
                                </b>
                            </h4>
                            <p>
                                {t('about.security.block_01.text')}
                            </p>
                        </div>
                    </div>
                    <Fade left>
                        <img className={classes.image} src={image_01} alt='' />
                    </Fade>
                    <div className="txt-container font-17">
                        <h4>
                            <b>
                                {t('about.security.block_02.title')}
                            </b>
                        </h4>
                        <p>
                            {t('about.security.block_02.text')}
                        </p>
                    </div>
                </div>
                <div className={`${classes.col} ${classes.gap50}`}>
                    <Fade right>
                        <img className={classes.image} src={image_02} alt='' />
                    </Fade>
                    <div className="txt-container font-17">
                        <h4>
                            <b>
                                {t('about.security.block_03.title')}
                            </b>
                        </h4>
                        <p>
                            {t('about.security.block_03.text')}
                        </p>
                    </div>
                    <div>
                        <Button />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Security;

import React, { useState } from "react";
import classes from './Tabs.module.scss';
import { useTranslation } from "react-i18next";

const tabs = [
    {
        title: 'about.help_center.tab_01.title',
        questions: [
            {
                q: 'about.help_center.tab_01.q1.q',
                a: 'about.help_center.tab_01.q1.a',
            },
            {
                q: 'about.help_center.tab_01.q2.q',
                a: 'about.help_center.tab_01.q2.a',
            },
            {
                q: 'about.help_center.tab_01.q3.q',
                a: 'about.help_center.tab_01.q3.a',
            },
        ],
    },
    {
        title: 'about.help_center.tab_02.title',
        questions: [
            {
                q: 'about.help_center.tab_02.q1.q',
                a: 'about.help_center.tab_02.q1.a',
            },
            {
                q: 'about.help_center.tab_02.q2.q',
                a: 'about.help_center.tab_02.q2.a',
            },
        ],
    },
    {
        title: 'about.help_center.tab_03.title',
        questions: [
            {
                q: 'about.help_center.tab_03.q1.q',
                a: 'about.help_center.tab_03.q1.a',
            },
            {
                q: 'about.help_center.tab_03.q2.q',
                a: 'about.help_center.tab_03.q2.a',
            },
            {
                q: 'about.help_center.tab_03.q3.q',
                a: 'about.help_center.tab_03.q3.a',
            },
        ],
    },
    {
        title: 'about.help_center.tab_04.title',
        questions: [
            {
                q: 'about.help_center.tab_04.q1.q',
                a: 'about.help_center.tab_04.q1.a',
            },
            {
                q: 'about.help_center.tab_04.q2.q',
                a: 'about.help_center.tab_04.q2.a',
            },
            {
                q: 'about.help_center.tab_04.q3.q',
                a: 'about.help_center.tab_04.q3.a',
            },
        ],
    },
];

const Tabs = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-30`}>
                    {t('about.help_center.title')}
                </h2>
                <p className={`${classes.text} font-17`}>
                    {t('about.help_center.text')}
                </p>
                <div className={classes.tabs}>
                    <div className={classes.buttons}>
                        {tabs.map((button, index) =>
                            <div
                                key={index}
                                className={`${classes.button} ${active === index ? classes.active : ''} font-17 no-select`}
                                onClick={() => setActive(index)}
                            >
                                {t(button.title)}
                            </div>
                        )}
                    </div>
                    <div className={classes.content}>
                        <h4 className="font-30">
                            {t(tabs[active].title)}
                        </h4>
                        <div className={classes.questions}>
                            {tabs[active].questions.map((q, index) =>
                                <div key={index} className={`${classes.question} font-17`}>
                                    <h6 className={classes.subtitle}>
                                        {t(q.q)}
                                    </h6>
                                    <p>
                                        {t(q.a)}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Tabs;

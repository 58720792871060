import React, { useState } from "react";
import classes from './Faqs.module.scss';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

const tabs = [
    {
        title: 'promotions.faqs.tab_01.title',
        text: 'promotions.faqs.tab_01.text',
    },
    {
        title: 'promotions.faqs.tab_02.title',
        text: 'promotions.faqs.tab_02.text',
    },
    {
        title: 'promotions.faqs.tab_03.title',
        text: 'promotions.faqs.tab_03.text',
    },
];

const Faqs = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-30`}>
                    <span>
                        {t('promotions.faqs.title')}
                    </span>
                </h2>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                        <Fade key={index} right>
                            <div className={`${classes.tab} ${active === index ? classes.active : ''} font-17`}>
                                <div
                                    className={`${classes.tabHeader} no-select`}
                                    onClick={() => setActive(index)}
                                >
                                    <h4 className={classes.tabTitle}>
                                        {t(tab.title)}
                                    </h4>
                                    <div className={classes.tabStatus}></div>
                                </div>
                                <p className={`${classes.tabText} txt-container`}>
                                    {t(tab.text)}
                                </p>
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Faqs;

import logo from '../assets/img/logo.svg';
import image_01 from '../assets/img/HomePage/Banner/icon_01.svg';
import image_02 from '../assets/img/HomePage/Banner/icon_02.svg';
import image_03 from '../assets/img/HomePage/Banner/icon_03.svg';
import image_04 from '../assets/img/HomePage/Banner/image.avif';
import image_05 from '../assets/img/TradingPage/banner.avif';
import image_06 from '../assets/img/PromotionsPage/banner.avif';
import image_07 from '../assets/img/AccountsPage/banner.avif';

export const images = [
    logo,
    image_01,
    image_02,
    image_03,
    image_04,
    image_05,
    image_06,
    image_07,
];

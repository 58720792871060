import React from "react";
import classes from './Underbanner.module.scss';
import { useTranslation } from "react-i18next";

const Underbanner = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={`${classes.wrap} font-17`}>
                <div className={`${classes.col} txt-container`}>
                    <p>
                        {t('trading.evo.underbanner.text_01')}
                    </p>
                    <p>
                        {t('trading.evo.underbanner.text_02')}
                    </p>
                    <p>
                        {t('trading.evo.underbanner.text_03')}
                    </p>
                </div>
                <div className={`${classes.col} txt-container`}>
                    <p>
                        <b>
                            {t('trading.evo.underbanner.text_04')}
                        </b>
                    </p>
                    <p>
                        {t('trading.evo.underbanner.text_05')}
                    </p>
                    <p>
                        {t('trading.evo.underbanner.text_06')}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Underbanner;

import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const cards = [
    {
        title: '8',
        text: 'about.banner.card_01.text',
    },
    {
        title: '12.000',
        text: 'about.banner.card_02.text',
    },
    {
        title: '10',
        text: 'about.banner.card_03.text',
    },
];

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.wrap}>
                <Fade left>
                    <h1 className={classes.title}>
                        <span>Arma LTD</span>
                    </h1>
                </Fade>
                <Fade right>
                    <h2 className={`${classes.subtitle} font-58`}>
                        {t('about.banner.subtitle')}
                    </h2>
                </Fade>
                <div className={`${classes.btn} mt-btn`}>
                    <Button />
                </div>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                    <Fade key={index} bottom>
                        <div className={classes.card}>
                            <p className={classes.num}>
                                {card.title}
                            </p>
                            <p className={`${classes.text} font-17`}>
                                {t(card.text)}
                            </p>
                        </div>
                    </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Banner;

import React from "react";
import classes from './Tier.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import image from '../../../assets/img/TradingPage/tier.jpg';

const cards = [
    'trading.tier.card_01.text',
    'trading.tier.card_02.text',
    'trading.tier.card_03.text',
];

const Tier = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.body}>
                    <div className={classes.content}>
                        <h2 className={`${classes.title} font-30`}>
                            <Trans>
                                {t('trading.tier.title')}
                            </Trans>
                        </h2>
                        <p className={`${classes.text} font-17`}>
                            {t('trading.tier.text')}
                        </p>
                        <p className={`${classes.desc} font-17`}>
                            {t('trading.tier.desc')}
                        </p>
                    </div>
                    <Fade right>
                        <img className={classes.image} src={image} alt='' />
                    </Fade>
                </div>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <Fade key={index} bottom>
                            <div className={`${classes.card} font-17`}>
                                <Trans>
                                    {t(card)}
                                </Trans>
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Tier;

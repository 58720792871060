import React from "react";
import classes from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/EvoPage/banner.jpg';
import Button from "../../GlobalComponents/Button/Button";

const cards = [
    {
        text: 'trading.evo.banner.card_01.text',
    },
    {
        text: 'trading.evo.banner.card_02.text',
    },
    {
        text: 'trading.evo.banner.card_03.text',
    },
    {
        text: 'trading.evo.banner.card_04.text',
    },
];

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.wrap}>
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h1 className={`${classes.title} font-30`}>
                            <Trans>
                                {t('trading.evo.banner.title')}
                            </Trans>
                        </h1>
                        <div className={`${classes.text} font-17 txt-container`}>
                            <p>
                                {t('trading.evo.banner.text_01')}
                            </p>
                            <p>
                                {t('trading.evo.banner.text_02')}
                            </p>
                        </div>
                        <div className={`${classes.btn} mt-btn`}>
                            <Button />
                        </div>
                    </div>
                    <div className={classes.image}>
                        <img src={image} alt='' />
                    </div>
                </div>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={`${classes.card} font-17`}>
                            <Trans>
                                {t(card.text)}
                            </Trans>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Banner;

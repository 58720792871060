import React from "react";
import classes from './Txt.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/TradingPage/txt.jpg';
import { Fade } from "react-reveal";

const list = [
    'trading.txt.li_01',
    'trading.txt.li_02',
    'trading.txt.li_03',
    'trading.txt.li_04',
];

const Txt = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={`${classes.content} font-17`}>
                    <p>
                        {t('trading.txt.text_01')}
                    </p>
                    <ul className={`${classes.list} font-17`}>
                        {list.map((li, index) =>
                            <li key={index} className={classes.item}>
                                <svg className={classes.check} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                    <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#B73D2B" />
                                </svg>
                                <p>
                                    {t(li)}
                                </p>
                            </li>
                        )}
                    </ul>
                    <p>
                        {t('trading.txt.text_02')}
                    </p>
                </div>
                <Fade right>
                    <img className={classes.image} src={image} alt='' />
                </Fade>
            </div>
        </section>
    );
};

export default Txt;

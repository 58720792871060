import React from "react";
import classes from './Regulation.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/regulation.avif';
import { Fade } from "react-reveal";

const Regulation = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.regulation}>
                <div className={classes.content}>
                    <h2 className="font-58">
                        {t('home.regulation.title')}
                    </h2>
                    <p className={`${classes.text} font-17`}>
                        {t('home.regulation.text')}
                    </p>
                </div>
                <Fade right>
                    <img className={classes.image} src={image} alt='' />
                </Fade>
            </div>
        </section>
    );
};

export default Regulation;

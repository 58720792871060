import _ from 'lodash';
import React, { useEffect, useState } from "react";
import classes from './Tabs.module.scss';
import { useTranslation } from "react-i18next";

const tabs = [
    {
        title: 'home.tabs.tab_01.title',
        text: 'home.tabs.tab_01.text',
    },
    {
        title: 'home.tabs.tab_02.title',
        text: 'home.tabs.tab_02.text',
    },
    {
        title: 'home.tabs.tab_03.title',
        text: 'home.tabs.tab_03.text',
    },
    {
        title: 'home.tabs.tab_04.title',
        text: 'home.tabs.tab_04.text',
    },
];

const candleDefaultState = [60, 20, 70, 80, 90, 95, 24, 30, 20, 80];

const Tabs = () => {
    const { t } = useTranslation();
    const [candles, setCandles] = useState(candleDefaultState);
    const [active, setActive] = useState(0);

    useEffect(() => {
        setCandles(_.map(Array.from({ length: 10 }, () => _.random(20, 100))));
    }, [ active ]);

    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.col}>
                    <div className={classes.graph}>
                        {Array.from({ length: 10 }).map((_, index) =>
                            <div
                                key={index}
                                className={classes.candle}
                            >
                                <div
                                    className={classes.candleWhite}
                                    style={{ height: `${candles[index]}%` }}
                                ></div>
                            </div>
                        )}
                    </div>
                    <div className={classes.content}>
                        <h4 className={`${classes.title} font-30`}>
                            {t(tabs[active].title)}
                        </h4>
                        <p className={`${classes.text} font-17`}>
                            {t(tabs[active].text)}
                        </p>
                    </div>
                </div>
                <div className={classes.buttons}>
                    {tabs.map((tab, index) =>
                        <div
                            key={index}
                            className={`${classes.button} ${active === index ? classes.active : ''} no-select`}
                            onClick={() => setActive(index)}
                        >
                            <div className={classes.buttonNum}>
                                0{index + 1}
                            </div>
                            <p className={`${classes.buttonText} font-17`}>
                                {t(tab.title)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Tabs;

import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";
import image from '../../../assets/img/CfdPage/banner.webp';

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <Fade left>
                        <h1 className={`${classes.title} font-58`}>
                            {t('trading.cfd.banner.title')}
                        </h1>
                    </Fade>
                    <Fade right>
                        <h2 className={`${classes.subtitle} font-17`}>
                            <b>
                                {t('trading.cfd.banner.subtitle')}
                            </b>
                        </h2>
                    </Fade>
                    <p className={`${classes.text} font-17`}>
                        {t('trading.cfd.banner.text')}
                    </p>
                    <div className={`${classes.btn} mt-btn`}>
                        <Button />
                    </div>
                </div>
                <Fade right>
                    <img className={classes.image} src={image} alt='' />
                </Fade>
            </div>
        </section>
    );
};

export default Banner;

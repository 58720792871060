import React from "react";
import classes from './Tabs.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/InvestingPage/tab_04.jpg';
import Button from "../../GlobalComponents/Button/Button";

const Tab4 = ({ data }) => {
    const { t } = useTranslation();
    return (
        <div className={classes.tab_04}>
            <div className={classes.head}>
                <div className={classes.image}>
                    <img src={image} alt='' />
                </div>
                <div className={classes.headContent}>
                    <h2 className={`${classes.title} font-30`}>
                        {t(data.title)}
                    </h2>
                    <div className={`${classes.gap15} ${classes.bold} font-17`}>
                        <p>
                            {t(data.head_text[0])}
                        </p>
                        <p>
                            {t(data.head_text[1])}
                        </p>
                    </div>
                    <div className="mt-btn">
                        <Button />
                    </div>
                </div>
            </div>
            <div className={`${classes.block} ${classes.mt50} font-17`}>
                <h4 className={classes.bold}>
                    {t(data.block_01.title)}
                </h4>
                <p>
                    {t(data.block_01.text)}
                </p>
            </div>
            <div className={classes.gap30}>
                <h4 className={`${classes.mt50} font-30`}>
                    {t(data.subtitle)}
                </h4>
                <div className={`${classes.gap30} font-17`}>
                    <div className={classes.block}>
                        <h4 className={classes.bold}>
                            {t(data.block_02.title)}
                        </h4>
                        <p>
                            {t(data.block_02.text)}
                        </p>
                    </div>
                    <div className={classes.block}>
                        <h4 className={classes.bold}>
                            {t(data.block_03.title)}
                        </h4>
                        <p>
                            {t(data.block_03.text)}
                        </p>
                    </div>
                    <div className={classes.block}>
                        <h4 className={classes.bold}>
                            {t(data.block_04.title)}
                        </h4>
                        <p>
                            {t(data.block_04.text)}
                        </p>
                    </div>
                    <div className={classes.block}>
                        <h4 className={classes.bold}>
                            {t(data.block_05.title)}
                        </h4>
                        <p>
                            {t(data.block_05.text)}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tab4;

import React from "react";
import classes from './Hours.module.scss';
import { useTranslation } from "react-i18next";

const list = [
    'trading.cfd.hours.li_01',
    'trading.cfd.hours.li_02',
    'trading.cfd.hours.li_03',
    'trading.cfd.hours.li_04',
    'trading.cfd.hours.li_05',
];

const Hours = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-30`}>
                    {t('trading.cfd.hours.title')}
                </h2>
                <div className={`${classes.cols} font-17`}>
                    <div className={classes.col}>
                        <p className={classes.text}>
                            {t('trading.cfd.hours.text')}
                        </p>
                        <ul className={classes.list}>
                            {list.map((li, index) =>
                                <li key={index} className={classes.item}>
                                    <svg className={classes.check} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                        <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#B73D2B" />
                                    </svg>
                                    <p>
                                        {t(li)}
                                    </p>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className={classes.col}>
                        <div className={classes.block}>
                            <h4 className={classes.subtitle}>
                                {t('trading.cfd.hours.block_01.title')}
                            </h4>
                            <p>
                                {t('trading.cfd.hours.block_01.text')}
                            </p>
                        </div>
                        <div className={classes.block}>
                            <h4 className={classes.subtitle}>
                                {t('trading.cfd.hours.block_02.title')}
                            </h4>
                            <p>
                                {t('trading.cfd.hours.block_02.text')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hours;

import React from "react";
import classes from './WhyInvest.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image from '../../../assets/img/TradingPage/WhyInvest/image_03.webp';
import { Fade } from "react-reveal";

const WhyInvest = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.cards}>
                    <div className={`${classes.card} ${classes.card_01}`}>
                        <h2 className={`${classes.title} font-30`}>
                            {t('trading.why_invest.card_01.title')}
                        </h2>
                        <p className={`${classes.text} font-17`}>
                            {t('trading.why_invest.card_01.text')}
                        </p>
                        <div className={classes.btn}>
                            <Button />
                        </div>
                    </div>
                    <Fade left>
                        <div className={`${classes.card} ${classes.card_02}`}>
                            <h4 className={`${classes.title} font-20`}>
                                {t('trading.why_invest.card_02.title')}
                            </h4>
                            <p className={`${classes.text} font-17`}>
                                {t('trading.why_invest.card_02.text')}
                            </p>
                        </div>
                    </Fade>
                    <Fade bottom>
                        <div className={`${classes.card} ${classes.card_03}`}>
                            <h4 className={`${classes.title} font-20`}>
                                {t('trading.why_invest.card_03.title')}
                            </h4>
                            <p className={`${classes.text} font-17`}>
                                {t('trading.why_invest.card_03.text')}
                            </p>
                        </div>
                    </Fade>
                    <Fade right>
                        <div className={`${classes.card} ${classes.card_04}`}>
                            <h4 className={`${classes.title} font-20`}>
                                {t('trading.why_invest.card_04.title')}
                            </h4>
                            <p className={`${classes.text} font-17`}>
                                {t('trading.why_invest.card_04.text')}
                            </p>
                            <img className={classes.image} src={image} alt='' />
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    );
};

export default WhyInvest;

import React from "react";
import classes from './Cards.module.scss';
import { useTranslation } from "react-i18next";

const cards = [
    {
        classes: classes.card_01,
        title: 'trading.cfd.cards.card_01.title',
        text: 'trading.cfd.cards.card_01.text',
    },
    {
        classes: classes.card_02,
        title: 'trading.cfd.cards.card_02.title',
        text: 'trading.cfd.cards.card_02.text',
    },
    {
        classes: classes.card_03,
        text: 'trading.cfd.cards.card_03.text',
    },
    {
        classes: classes.card_04,
        title: 'trading.cfd.cards.card_04.title',
        text: 'trading.cfd.cards.card_04.text',
    },
];

const Cards = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={`${classes.card} ${card.classes}`}>
                            <div className={classes.content}>
                                {card.title &&
                                    <h4 className={`${classes.title} font-25`}>
                                        {t(card.title)}
                                    </h4>
                                }
                                <p className={`${classes.text} font-17`}>
                                    {t(card.text)}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Cards;

import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/HomePage/Banner/icon_01.svg';
import icon_02 from '../../../assets/img/HomePage/Banner/icon_02.svg';
import icon_03 from '../../../assets/img/HomePage/Banner/icon_03.svg';
import image from '../../../assets/img/HomePage/Banner/image.avif';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const list = [
    {
        icon: icon_01,
        text: 'home.banner.li_01',
    },
    {
        icon: icon_02,
        text: 'home.banner.li_02',
    },
    {
        icon: icon_03,
        text: 'home.banner.li_03',
    },
];

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.banner}>
                <div className={classes.content}>
                    <Fade left>
                        <h1 className={classes.title}>
                            <span>Arma LTD</span>
                        </h1>
                    </Fade>
                    <Fade right>
                        <h2 className={`${classes.subtitle} font-58`}>
                            {t('home.banner.subtitle')}
                        </h2>
                    </Fade>
                        <div className={classes.btn}>
                            <Button />
                        </div>
                    <Fade right>
                        <img className={classes.image} src={image} alt='' />
                    </Fade>
                    <ul className={`${classes.list} font-17`}>
                        {list.map((li, index) =>
                            <li className={classes.item} key={index}>
                                <img className={classes.itemIcon} src={li.icon} alt='' />
                                <span>
                                    {t(li.text)}
                                </span>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default Banner;

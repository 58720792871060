import React from "react";
import classes from './Tabs.module.scss';
import { useTranslation } from "react-i18next";
import Button from '../../GlobalComponents/Button/Button';
import check from '../../../assets/img/AccountsPage/list_check.svg';

const Tab1 = ({ data }) => {
    const { t } = useTranslation();
    return (
        <div className={classes.tab_01}>
            <h2 className={`${classes.title} font-30`}>
                {t(data.title)}
            </h2>
            <ul className={classes.bigList}>
                {data.list.map((li, index) =>
                    <li key={index} className={classes.item}>
                        <img className={classes.check} src={check} alt='' />
                        <div className={`${classes.itemContent} font-17`}>
                            <h4 className={classes.subtitle}>
                                <b>
                                    {t(li.title)}
                                </b>
                            </h4>
                            <p className={classes.text}>
                                {t(li.text)}
                            </p>
                        </div>
                    </li>
                )}
            </ul>
            <div className={`${classes.btn} mt-btn`}>
                <Button />
            </div>
        </div>
    );
};

export default Tab1;

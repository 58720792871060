import React from "react";
import classes from './Commodity.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/TradingPage/commodity.avif';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const Commodity = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-30`}>
                        {t('trading.commodity.title')}
                    </h2>
                    <p className={`${classes.text} font-17`}>
                        {t('trading.commodity.text')}
                    </p>
                    <div className={`${classes.btn} mt-btn`}>
                        <Button />
                    </div>
                </div>
                <Fade right>
                    <div className={classes.image}>
                        <img src={image} alt='' />
                    </div>
                </Fade>
            </div>
        </section>
    );
};

export default Commodity;

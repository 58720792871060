import React from "react";
import classes from './WhoWe.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AboutPage/who_we.avif';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const WhoWe = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-30`}>
                        {t('about.who_we.title')}
                    </h2>
                    <p className={`${classes.text} font-17 txt-container`}>
                        {t('about.who_we.text')}
                    </p>
                    <div className={`${classes.btn} mt-btn`}>
                        <Button />
                    </div>
                </div>
                <Fade right>
                <div className={classes.image}>
                    <img src={image} alt='' />
                </div>
                </Fade>
            </div>
        </section>
    );
};

export default WhoWe;

import React from "react";
import classes from './Cryptocurrencies.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import check from '../../../assets/img/AccountsPage/list_check.svg';
import { Fade } from "react-reveal";

const list = [
    'trading.cryptocurrencies.li_01',
    'trading.cryptocurrencies.li_02',
    'trading.cryptocurrencies.li_03',
];

const Cryptocurrencies = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-30`}>
                    {t('trading.cryptocurrencies.title')}
                </h2>
                <div className={classes.body}>
                    <div className={classes.content}>
                        <div className={`${classes.text} txt-container font-17`}>
                            <p>
                                {t('trading.cryptocurrencies.text_01')}
                            </p>
                            <p>
                                {t('trading.cryptocurrencies.text_02')}
                            </p>
                            <p>
                                {t('trading.cryptocurrencies.text_03')}
                            </p>
                        </div>
                        <div className={`${classes.btn} mt-btn`}>
                            <Button />
                        </div>
                    </div>
                    <ul className={classes.list}>
                        {list.map((li, index) =>
                            <Fade key={index} right>
                                <li className={`${classes.item} font-17`}>
                                    <img className={classes.check} src={check} alt='' />
                                    {t(li)}
                                </li>
                            </Fade>
                        )}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default Cryptocurrencies;

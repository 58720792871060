import React, { useState } from "react";
import classes from './UnderBanner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const tabs = [
    {
        title: 'trading.underbanner.tab_01.title',
        text: [
            'trading.underbanner.tab_01.text_01',
            'trading.underbanner.tab_01.text_02',
        ],
    },
    {
        title: 'trading.underbanner.tab_02.title',
        text: [
            'trading.underbanner.tab_02.text_01',
            'trading.underbanner.tab_02.text_02',
        ],
    },
    {
        title: 'trading.underbanner.tab_03.title',
        text: [
            'trading.underbanner.tab_03.text_01',
            'trading.underbanner.tab_03.text_02',
        ],
    },
];

const UnderBanner = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-30`}>
                        {t('trading.underbanner.title')}
                    </h2>
                    <div className={`${classes.btn} mt-btn`}>
                        <Button />
                    </div>
                </div>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                    <Fade key={index} right>
                        <div className={`${classes.tab} ${active === index ? classes.active : ''} font-17`}>
                            <div
                                className={`${classes.tabHeader} no-select`}
                                onClick={() => setActive(index)}
                            >
                                <h4 className={classes.tabTitle}>
                                    {t(tab.title)}
                                </h4>
                                <div className={classes.tabStatus}></div>
                            </div>
                            <div className={`${classes.tabText} txt-container`}>
                                <p>
                                    {t(tab.text[0])}
                                </p>
                                <p>
                                    {t(tab.text[1])}
                                </p>
                            </div>
                        </div>
                    </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default UnderBanner;

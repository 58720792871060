import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import KeyFeatures from "../components/PromotionsPage/KeyFeatures/KeyFeatures";
import Transfers from "../components/PromotionsPage/Transfers/Transfers";
import Faqs from "../components/PromotionsPage/Faqs/Faqs";
import WhyChoose from "../components/PromotionsPage/WhyChoose/WhyChoose";
import Banner from "../components/PromotionsPage/Banner/Banner";

const PromotionsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Arma LTD · Promotions</title>
        <meta name="title" content="Arma LTD Trading Promotions - Boost Your Trading Potential" />
        <meta property="og:title" content="Arma LTD Trading Promotions - Boost Your Trading Potential" />
        <meta property="twitter:title" content="Arma LTD Trading Promotions - Boost Your Trading Potential" />

        <meta name="description" content="Maximize your trading potential with Arma LTD's exclusive promotions. Explore offers, bonuses, and incentives to enhance your trading experience." />
        <meta property="og:description" content="Maximize your trading potential with Arma LTD's exclusive promotions. Explore offers, bonuses, and incentives to enhance your trading experience." />
        <meta property="twitter:description" content="Maximize your trading potential with Arma LTD's exclusive promotions. Explore offers, bonuses, and incentives to enhance your trading experience." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <WhyChoose />
        <Faqs />
        <Transfers />
        <KeyFeatures />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default PromotionsPage;

import React from "react";

const GoogleMap = () => {

    return (
        <section className="mt container">
           <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9719.160637593106!2d-1.9009718!3d52.482935!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870bdcc1f3ccf47%3A0xcd5373af164c4266!2sArma!5e0!3m2!1sen!2sua!4v1723471680178!5m2!1sen!2sua" width="100%" height="244" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </section>
    );
};

export default GoogleMap;

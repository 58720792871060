import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import ReadyTo from "../components/HomePage/ReadyTo/ReadyTo";
import WhyOpen from "../components/AccountsPage/WhyOpen/WhyOpen";
import ChooseRight from "../components/AccountsPage/ChooseRight/ChooseRight";
import Accounts from "../components/AccountsPage/Accounts/Accounts";
import Banner from "../components/AccountsPage/Banner/Banner";

const AccountsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Arma LTD · Accounts</title>
        <meta name="title" content="Arma LTD Account Types - Tailored Solutions for Every Trader" />
        <meta property="og:title" content="Arma LTD Account Types - Tailored Solutions for Every Trader" />
        <meta property="twitter:title" content="Arma LTD Account Types - Tailored Solutions for Every Trader" />

        <meta name="description" content="Explore Arma LTD's range of account types, designed to suit traders of all levels. Choose the account that aligns with your trading goals." />
        <meta property="og:description" content="Explore Arma LTD's range of account types, designed to suit traders of all levels. Choose the account that aligns with your trading goals." />
        <meta property="twitter:description" content="Explore Arma LTD's range of account types, designed to suit traders of all levels. Choose the account that aligns with your trading goals." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Accounts />
        <ChooseRight />
        <WhyOpen />
        <ReadyTo />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AccountsPage;

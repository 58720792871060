import React from "react";
import classes from './Understand.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/TradingPage/understand.avif';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const Understand = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-30`}>
                        {t('trading.understand.title')}
                    </h2>
                    <div className={`${classes.text} font-17 txt-container`}>
                        <p>
                            {t('trading.understand.text_01')}
                        </p>
                        <p>
                            {t('trading.understand.text_02')}
                        </p>
                    </div>
                    <div className={`${classes.btn} mt-btn`}>
                        <Button />
                    </div>
                </div>
                <Fade left>
                    <div className={classes.image}>
                        <img src={image} alt='' />
                    </div>
                </Fade>
            </div>
        </section>
    );
};

export default Understand;

import React from "react";
import classes from './OurAwards.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/OurAwards/image_01.jpg';
import image_02 from '../../../assets/img/HomePage/OurAwards/image_02.jpg';
import image_03 from '../../../assets/img/HomePage/OurAwards/image_03.jpg';
import image_04 from '../../../assets/img/HomePage/OurAwards/image_04.jpg';
import image_05 from '../../../assets/img/HomePage/OurAwards/image_05.jpg';
import { Fade } from "react-reveal";

const awards = [
    image_01,
    image_02,
    image_03,
    image_04,
    image_05,
];

const OurAwards = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-58`}>
                    <Trans>
                        {t('home.our_awards.title')}
                    </Trans>
                </h2>
                <div className={classes.awards}>
                    {awards.map((award, index) =>
                    <Fade key={index} bottom>
                        <img className={classes.award} src={award} alt='' />
                    </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default OurAwards;

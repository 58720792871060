import React, { useState } from "react";
import classes from './OptionsFaq.module.scss';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

const tabs = [
    {
        title: 'trading.options_faq.tab_01.title',
        text: [
            'trading.options_faq.tab_01.text_01',
        ],
    },
    {
        title: 'trading.options_faq.tab_02.title',
        text: [
            'trading.options_faq.tab_02.text_01',
            'trading.options_faq.tab_02.text_02',
            'trading.options_faq.tab_02.text_03',
            'trading.options_faq.tab_02.text_04',
        ],
    },
    {
        title: 'trading.options_faq.tab_03.title',
        text: [
            'trading.options_faq.tab_03.text_01',
            'trading.options_faq.tab_03.text_02',
            'trading.options_faq.tab_03.text_03',
            'trading.options_faq.tab_03.text_04',
        ],
    },
];

const OptionsFaq = () => {
    const { t } = useTranslation();
    const [ active, setActive ] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-30`}>
                    <span>
                        {t('trading.options_faq.title')}
                    </span>
                </h2>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                        <Fade key={index} right>
                            <div className={`${classes.tab} ${active === index ? classes.active : ''} font-17`}>
                                <div
                                    className={`${classes.tabHeader} no-select`}
                                    onClick={() => setActive(index)}
                                >
                                    <h4 className={classes.tabTitle}>
                                        {t(tab.title)}
                                    </h4>
                                    <div className={classes.tabStatus}></div>
                                </div>
                                <div className={`${classes.tabText} txt-container`}>
                                    {tab.text.map((txt, txtIndex) =>
                                        <p key={txtIndex}>
                                            {t(txt)}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default OptionsFaq;

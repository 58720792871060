import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/TradingPage/Banner/Banner";
import UnderBanner from "../../components/TradingPage/UnderBanner/UnderBanner";
import Powerful from "../../components/TradingPage/Powerful/Powerful";
import Understand from "../../components/TradingPage/Understand/Understand";
import Options from "../../components/TradingPage/Options/Options";
import OptionsFaq from "../../components/TradingPage/OptionsFaq/OptionsFaq";
import InDepth from "../../components/TradingPage/InDepth/InDepth";
import WhyInvest from "../../components/TradingPage/WhyInvest/WhyInvest";
import Discover from "../../components/TradingPage/Discover/Discover";
import Commodity from "../../components/TradingPage/Commodity/Commodity";
import ChooseYour from "../../components/TradingPage/ChooseYour/ChooseYour";
import KeyPoints from "../../components/TradingPage/KeyPoints/KeyPoints";
import Cryptocurrencies from "../../components/TradingPage/Cryptocurrencies/Cryptocurrencies";
import Opportunities from "../../components/TradingPage/Opportunities/Opportunities";
import Tier from "../../components/TradingPage/Tier/Tier";
import Txt from "../../components/TradingPage/Txt/Txt";
import ReadyTo from "../../components/HomePage/ReadyTo/ReadyTo";

const TradingPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Arma LTD · Trading</title>
        <meta name="title" content="Trading with Arma LTD - Expertise at Your Fingertips" />
        <meta property="og:title" content="Trading with Arma LTD - Expertise at Your Fingertips" />
        <meta property="twitter:title" content="Trading with Arma LTD - Expertise at Your Fingertips" />

        <meta name="description" content="Discover the world of trading on Arma LTD. Gain access to powerful tools and resources for informed trading decisions across various asset classes." />
        <meta property="og:description" content="Discover the world of trading on Arma LTD. Gain access to powerful tools and resources for informed trading decisions across various asset classes." />
        <meta property="twitter:description" content="Discover the world of trading on Arma LTD. Gain access to powerful tools and resources for informed trading decisions across various asset classes." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <UnderBanner />
        <Powerful />
        <Understand />
        <Options />
        <ChooseYour />
        <OptionsFaq />
        <InDepth />
        <WhyInvest />
        <Discover />
        <Commodity />
        <KeyPoints />
        <Cryptocurrencies />
        <Opportunities />
        <Tier />
        <Txt />
        <ReadyTo />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default TradingPage;

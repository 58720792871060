import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/IslamicPage/Banner/Banner";
import Principles from "../../components/IslamicPage/Principles/Principles";
import Islamic from "../../components/IslamicPage/Islamic/Islamic";
import Steps from "../../components/IslamicPage/Steps/Steps";

const IslamicPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Arma LTD · Islamic Trading Account</title>
        <meta name="title" content="Trading with Arma LTD - Expertise at Your Fingertips" />
        <meta property="og:title" content="Trading with Arma LTD - Expertise at Your Fingertips" />
        <meta property="twitter:title" content="Trading with Arma LTD - Expertise at Your Fingertips" />

        <meta name="description" content="Discover the world of trading on Arma LTD. Gain access to powerful tools and resources for informed trading decisions across various asset classes." />
        <meta property="og:description" content="Discover the world of trading on Arma LTD. Gain access to powerful tools and resources for informed trading decisions across various asset classes." />
        <meta property="twitter:description" content="Discover the world of trading on Arma LTD. Gain access to powerful tools and resources for informed trading decisions across various asset classes." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Principles />
        <Islamic />
        <Steps />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default IslamicPage;

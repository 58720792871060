import React from "react";
import classes from './Tabs.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";

const Tab2 = ({ data }) => {
    const { t } = useTranslation();
    return (
        <div className={classes.tab_02}>
            <h2 className={`${classes.title} font-30`}>
                {t(data.title)}
            </h2>
            <div className="font-17">
                <p>
                    <Trans>
                        {t(data.text)}
                    </Trans>
                </p>
                <ul className={classes.list}>
                    {data.list_01.map((li, index) =>
                        <li key={index} className={classes.item}>
                            <svg className={classes.check} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#B73D2B" />
                            </svg>
                            <p>
                                {t(li)}
                            </p>
                        </li>
                    )}
                </ul>
                <h4 className={`${classes.mt50} ${classes.bold}`}>
                    {t(data.subtitle_01)}
                </h4>
                <ul className={classes.list}>
                    {data.list_02.map((li, index) =>
                        <li key={index} className={classes.item}>
                            <svg className={classes.check} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#B73D2B" />
                            </svg>
                            <p>
                                {t(li)}
                            </p>
                        </li>
                    )}
                </ul>
                <div className={classes.block}>
                    <h4 className={`${classes.mt50} ${classes.bold}`}>
                        {t(data.block.subtitle)}
                    </h4>
                    <p>
                        {t(data.block.text)}
                    </p>
                </div>
            </div>

            <div className={`${classes.btn} mt-btn`}>
                <Button />
            </div>
        </div>
    );
};

export default Tab2;

import React from "react";
import classes from './WhyChoose.module.scss';
import { useTranslation } from "react-i18next";
import check from '../../../assets/img/AccountsPage/list_check.svg';
import { Fade } from "react-reveal";

const cards = [
    {
        title: 'about.why_choose.card_01.title',
        text: 'about.why_choose.card_01.text',
    },
    {
        title: 'about.why_choose.card_02.title',
        text: 'about.why_choose.card_02.text',
    },
    {
        title: 'about.why_choose.card_03.title',
        text: 'about.why_choose.card_03.text',
    },
    {
        title: 'about.why_choose.card_04.title',
        text: 'about.why_choose.card_04.text',
    },
];

const WhyChoose = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-30`}>
                    {t('about.why_choose.title')}
                </h2>
                <ul className={classes.list}>
                    {cards.map((card, index) =>
                        <Fade
                            key={index}
                            left={(index === 0 || index === 2) ? true : false}
                            right={(index === 1 || index === 3) ? true : false}
                        >
                            <li className={classes.item}>
                                <img className={classes.check} src={check} alt='' />
                                <div className={`${classes.itemContent} font-17`}>
                                    <h4 className={classes.subtitle}>
                                        <b>
                                            {t(card.title)}
                                        </b>
                                    </h4>
                                    <p className={classes.text}>
                                        {t(card.text)}
                                    </p>
                                </div>
                            </li>
                        </Fade>
                    )}
                </ul>
            </div>
        </section>
    );
};

export default WhyChoose;

import React from "react";
import classes from './OurValues.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image from '../../../assets/img/AboutPage/our_values.webp';
import { Fade } from "react-reveal";

const OurValues = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <Fade bottom>
                    <div className={`${classes.card} ${classes.card_01}`}>
                        <div className={classes.content}>
                            <h2 className="font-30">
                                <span>
                                    {t('about.our_values.card_01.title')}
                                </span>
                            </h2>
                            <p className={`${classes.text} font-17`}>
                                {t('about.our_values.card_01.text')}
                            </p>
                            <div className={`${classes.btn} mt-btn`}>
                                <Button />
                            </div>
                        </div>
                        <div className={classes.image}>
                            <img src={image} alt='' />
                        </div>
                    </div>
                </Fade>
                <Fade left>
                    <div className={`${classes.card} ${classes.card_02} font-17 txt-container`}>
                        <h2 className={classes.subtitle}>
                            {t('about.our_values.card_02.title')}
                        </h2>
                        <p>
                            {t('about.our_values.card_02.text')}
                        </p>
                    </div>
                </Fade>
                <Fade right>
                    <div className={`${classes.card} ${classes.card_03} font-17 txt-container`}>
                        <h2 className={classes.subtitle}>
                            {t('about.our_values.card_03.title')}
                        </h2>
                        <p>
                            {t('about.our_values.card_03.text')}
                        </p>
                    </div>
                </Fade>
            </div>
        </section>
    );
};

export default OurValues;

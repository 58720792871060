import React from "react";
import classes from './Transfers.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/PromotionsPage/transfers.avif';
import { Fade } from "react-reveal";

const Transfers = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-30`}>
                        {t('promotions.transfers.title')}
                    </h2>
                    <div className={`${classes.text} font-17 txt-container`}>
                        <p>
                            {t('promotions.transfers.text_01')}
                        </p>
                        <p>
                            {t('promotions.transfers.text_02')}
                        </p>
                        <p>
                            {t('promotions.transfers.text_03')}
                        </p>
                    </div>
                </div>
                <Fade right>
                    <div className={classes.image}>
                        <img src={image} alt='' />
                    </div>
                </Fade>
            </div>
        </section>
    );
};

export default Transfers;

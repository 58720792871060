import React, { useState } from "react";
import classes from './Tabs.module.scss';
import { useTranslation } from "react-i18next";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import Tab4 from "./Tab4";
import Tab5 from "./Tab5";

const tabs = [
    {
        title: 'trading.investing.tab_01.title',
        list: [
            {
                title: 'trading.investing.tab_01.card_01.title',
                text: 'trading.investing.tab_01.card_01.text',
            },
            {
                title: 'trading.investing.tab_01.card_02.title',
                text: 'trading.investing.tab_01.card_02.text',
            },
        ],
    },
    {
        title: 'trading.investing.tab_02.title',
        text: 'trading.investing.tab_02.text',
        list_01: [
            'trading.investing.tab_02.li_01',
            'trading.investing.tab_02.li_02',
        ],
        subtitle_01: 'trading.investing.tab_02.subtitle_01',
        list_02: [
            'trading.investing.tab_02.li_03',
            'trading.investing.tab_02.li_04',
            'trading.investing.tab_02.li_05',
            'trading.investing.tab_02.li_06',
        ],
        block: {
            subtitle: 'trading.investing.tab_02.subtitle_02',
            text: 'trading.investing.tab_02.text_02',
        },
    },
    {
        title: 'trading.investing.tab_03.title',
        text_01: 'trading.investing.tab_03.text_01',
        text_02: 'trading.investing.tab_03.text_02',
    },
    {
        title: 'trading.investing.tab_04.title',
        head_text: [
            'trading.investing.tab_04.text_01',
            'trading.investing.tab_04.text_02',
        ],
        block_01: {
            title: 'trading.investing.tab_04.block_01.title',
            text: 'trading.investing.tab_04.block_01.text',
        },
        subtitle: 'trading.investing.tab_04.subtitle',
        block_02: {
            title: 'trading.investing.tab_04.block_02.title',
            text: 'trading.investing.tab_04.block_02.text',
        },
        block_03: {
            title: 'trading.investing.tab_04.block_03.title',
            text: 'trading.investing.tab_04.block_03.text',
        },
        block_04: {
            title: 'trading.investing.tab_04.block_04.title',
            text: 'trading.investing.tab_04.block_04.text',
        },
        block_05: {
            title: 'trading.investing.tab_04.block_05.title',
            text: 'trading.investing.tab_04.block_05.text',
        },
    },
    {
        title: 'trading.investing.tab_05.title',
        text_01: 'trading.investing.tab_05.text_01',
        text_02: 'trading.investing.tab_05.text_02',
        text_03: 'trading.investing.tab_05.text_03',
        subtitle: 'trading.investing.tab_05.subtitle',
        text_04: 'trading.investing.tab_05.text_04',
        block_01: {
            title: 'trading.investing.tab_05.block_01.title',
            list: [
                'trading.investing.tab_05.block_01.card_01.text',
                'trading.investing.tab_05.block_01.card_02.text',
                'trading.investing.tab_05.block_01.card_03.text',
                'trading.investing.tab_05.block_01.card_04.text',
            ],
        },
        block_02: {
            title: 'trading.investing.tab_05.block_02.title',
            list: [
                {
                    title: 'trading.investing.tab_05.block_02.card_01.title',
                    text: 'trading.investing.tab_05.block_02.card_01.text',
                },
                {
                    title: 'trading.investing.tab_05.block_02.card_02.title',
                    text: 'trading.investing.tab_05.block_02.card_02.text',
                },
                {
                    title: 'trading.investing.tab_05.block_02.card_03.title',
                    text: 'trading.investing.tab_05.block_02.card_03.text',
                },
            ],
        }
    },
];

const Tabs = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.buttons}>
                    {tabs.map((tab, index) =>
                        <div 
                            key={index}
                            onClick={() => setActive(index)}
                            className={`${classes.button} ${active === index ? classes.active : ''} font-17 no-select`}
                        >
                            {t(tab.title)}
                        </div>
                    )}
                </div>
                <div className={classes.content}>
                    {active === 0 && <Tab1 data={tabs[0]} />}
                    {active === 1 && <Tab2 data={tabs[1]} />}
                    {active === 2 && <Tab3 data={tabs[2]} />}
                    {active === 3 && <Tab4 data={tabs[3]} />}
                    {active === 4 && <Tab5 data={tabs[4]} />}
                </div>
            </div>
        </section>
    );
};

export default Tabs;

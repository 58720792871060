import React from "react";
import classes from './Islamic.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image from '../../../assets/img/IslamicPage/islamic.jpg';
import { Fade } from "react-reveal";

const Islamic = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-30`}>
                        {t('trading.islamic.islamic.title')}
                    </h2>
                    <div className={`${classes.text} txt-container font-17`}>
                        <p>
                            {t('trading.islamic.islamic.text_01')}
                        </p>
                        <p>
                            {t('trading.islamic.islamic.text_02')}
                        </p>
                    </div>
                    <div className={`${classes.btn} mt-btn`}>
                        <Button />
                    </div>
                </div>
                <Fade right>
                    <img className={classes.image} src={image} alt='' />
                </Fade>
            </div>
        </section>
    );
};

export default Islamic;

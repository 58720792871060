import React from "react";
import classes from './WhyChoose.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

const cards = [
    {
        title: 'promotions.why_choose.card_01.title',
        subtitle: 'promotions.why_choose.card_01.subtitle',
        text: 'promotions.why_choose.card_01.text',
    },
    {
        title: 'promotions.why_choose.card_02.title',
        subtitle: 'promotions.why_choose.card_02.subtitle',
        text: 'promotions.why_choose.card_02.text',
    },
    {
        title: 'promotions.why_choose.card_03.title',
        subtitle: 'promotions.why_choose.card_03.subtitle',
        text: 'promotions.why_choose.card_03.text',
    },
    {
        title: 'promotions.why_choose.card_04.title',
        subtitle: 'promotions.why_choose.card_04.subtitle',
        text: 'promotions.why_choose.card_04.text',
    },
];

const WhyChoose = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-30`}>
                    <Trans>
                        {t('promotions.why_choose.title')}
                    </Trans>
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <Fade
                            key={index}
                            left={(index === 0 || index === 2) ? true : false}
                            right={(index === 1 || index === 3) ? true : false}
                        >
                            <div className={`${classes.card} font-17 txt-container`}>
                                <p>
                                    <b>
                                        {t(card.title)}
                                    </b>
                                </p>
                                <p>
                                    <b>
                                        {t(card.subtitle)}
                                    </b>
                                </p>
                                <p>
                                    {t(card.text)}
                                </p>
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default WhyChoose;

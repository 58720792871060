import HomePage from '../pages/HomePage';
import TradingPage from '../pages/Trading/TradingPage';
import EvoPage from '../pages/Trading/EvoPage';
import CfdPage from '../pages/Trading/CfdPage';
import InvestingPage from '../pages/Trading/InvestingPage';
import StakingPage from '../pages/Trading/StakingPage';
import IslamicPage from '../pages/Trading/IslamicPage';
import PromotionsPage from '../pages/PromotionsPage';
import AccountsPage from '../pages/AccountsPage';
import AboutPage from '../pages/AboutPage';

export const routes = [
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/trading', element: TradingPage },
    { path: '/:lang/trading/evotrade', element: EvoPage },
    { path: '/:lang/trading/cfd', element: CfdPage },
    { path: '/:lang/trading/investing', element: InvestingPage },
    { path: '/:lang/trading/staking', element: StakingPage },
    { path: '/:lang/trading/islamic', element: IslamicPage },
    { path: '/:lang/promotions', element: PromotionsPage },
    { path: '/:lang/accounts', element: AccountsPage },
    { path: '/:lang/about', element: AboutPage },
];

export const headerRoutes = [
    {
        text: 'header.trading',
        path: '/trading',
        routes: [
            { path: '/trading/evotrade', text: 'header.trading.evotrade' },
            { path: '/trading/cfd', text: 'header.trading.cfd_trading' },
            { path: '/trading/investing', text: 'header.trading.investing' },
            { path: '/trading/staking', text: 'header.trading.staking_crypto' },
            { path: '/trading/islamic', text: 'header.trading.islamic' },
        ],
    },
    { path: '/promotions', text: 'header.promotions' },
    { path: '/accounts', text: 'header.accounts' },
    { path: '/about', text: 'header.about' },
];

import React, { useState } from "react";
import classes from './Accounts.module.scss';
import { useTranslation } from "react-i18next";
import Button from '../../GlobalComponents/Button/Button';
import true_svg from '../../../assets/img/AccountsPage/Accounts/true.svg';
import false_svg from '../../../assets/img/AccountsPage/Accounts/false.svg';

const accounts = [
    {
        title: 'Standard',
        text: 'accounts.accounts.01.text',
        rows: [
            'accounts.accounts.bid',
            '$250',
            'TRADE Platform',
            '0.00003',
            '0',
            '6 USD/lot',
            'accounts.accounts.commissions_cfd.01',
            'EUR, USD',
            true,
            true,
            '0.01',
            '100,000',
            'accounts.accounts.unlimited',
        ],
    },
    {
        title: 'Premium',
        text: 'accounts.accounts.02.text',
        rows: [
            'accounts.accounts.bid',
            '$500',
            'TRADE Platform',
            '0.00013',
            ' 1:400',
            false,
            'accounts.accounts.commissions_cfd.01',
            'EUR, USD',
            true,
            true,
            '0.01',
            '100,000',
            'accounts.accounts.unlimited',
        ],
    },
    {
        title: 'PRO',
        text: 'accounts.accounts.03.text',
        rows: [
            'accounts.accounts.bid',
            '$1000',
            'TRADE Platform',
            '0.0002',
            ' 1:600',
            false,
            'accounts.accounts.commissions_cfd.03',
            'EUR, USD',
            true,
            true,
            '0.01',
            '100,000',
            'accounts.accounts.unlimited',
        ],
    },
    {
        title: 'VIP',
        text: 'accounts.accounts.04.text',
        rows: [
            'accounts.accounts.bid',
            '$5000',
            'TRADE Platform',
            '0.00003',
            ' 1:800',
            '6 USD/Lot',
            'accounts.accounts.commissions_cfd.04',
            'EUR, USD',
            true,
            true,
            '0.01',
            '100,000',
            'accounts.accounts.unlimited',
        ],
    },
    {
        title: 'Islamic',
        text: 'accounts.accounts.05.text',
        rows: [
            'accounts.accounts.bid',
            '$250',
            'TRADE Platform',
            '0.0001',
            ' 1:500',
            false,
            false,
            'EUR, USD, GBP',
            true,
            true,
            '0.01',
            '100,000',
            'accounts.accounts.unlimited',
        ],
    },
];

const rowTitles = [
    'accounts.accounts.row_title_01',
    'accounts.accounts.row_title_02',
    'accounts.accounts.row_title_03',
    'accounts.accounts.row_title_04',
    'accounts.accounts.row_title_05',
    'accounts.accounts.row_title_06',
    'accounts.accounts.row_title_07',
    'accounts.accounts.row_title_08',
    'accounts.accounts.row_title_09',
    'accounts.accounts.row_title_10',
    'accounts.accounts.row_title_11',
    'accounts.accounts.row_title_12',
    'accounts.accounts.row_title_13',
];

const Accounts = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-30`}>
                        {accounts[active].title}
                    </h2>
                    <p className={`${classes.text} font-17`}>
                        {t(accounts[active].text)}
                    </p>
                    <div className={`${classes.btn} mt-btn`}>
                        <Button />
                    </div>
                </div>
                <div className={classes.accountsWrap}>
                    <div className={classes.buttons}>
                        {accounts.map((account, index) =>
                            <div
                                key={index}
                                onClick={() => setActive(index)}
                                className={`${classes.button} ${active === index ? classes.active : ''} no-select`}
                            >
                                {t(account.title)}
                            </div>
                        )}
                    </div>
                    <div className={classes.accounts}>
                        {accounts[active].rows.map((row, index) =>
                            <div key={index} className={classes.row}>
                                <div className={classes.col}>
                                    {t(rowTitles[index])}
                                </div>
                                <div className={classes.col}>
                                    {typeof (row) === 'boolean' ? (
                                        row === true ?
                                            <img src={true_svg} alt='' />
                                            :
                                            <img src={false_svg} alt='' />
                                    ) : (
                                        <>
                                            {t(row)}
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                        {active === 4 &&
                            <div className={classes.row}>
                                <div className={classes.col}>
                                    {t('accounts.accounts.row_title_14')}
                                </div>
                                <div className={classes.col}>
                                    <img src={true_svg} alt='' />
                                </div>
                            </div>
                        }
                        {active === 4 &&
                            <div className={classes.row}>
                                <div className={classes.col}>
                                    {t('accounts.accounts.row_title_15')}
                                </div>
                                <div className={classes.col}>
                                    {t('accounts.accounts.yes_ovrn_positions')}
                                </div>
                            </div>
                        }
                        {active === 4 &&
                            <div className={classes.row}>
                                <div className={classes.col}>
                                    {t('accounts.accounts.row_title_16')}
                                </div>
                                <div className={classes.col}>
                                    <img src={true_svg} alt='' />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Accounts;

import React from "react";
import classes from './ChooseRight.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AccountsPage/choose_right.avif';
import check from '../../../assets/img/AccountsPage/list_check.svg';
import { Fade } from "react-reveal";

const cards = [
    {
        title: 'accounts.choose_right.card_01.title',
        text: 'accounts.choose_right.card_01.text',
    },
    {
        title: 'accounts.choose_right.card_02.title',
        text: 'accounts.choose_right.card_02.text',
    },
    {
        title: 'accounts.choose_right.card_03.title',
        text: 'accounts.choose_right.card_03.text',
    },
];

const ChooseRight = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-30`}>
                    {t('accounts.choose_right.title')}
                </h2>
                <div className={classes.content}>
                    <Fade left>
                        <div className={classes.image}>
                            <img src={image} alt='' />
                        </div>
                    </Fade>
                    <ul className={classes.list}>
                        {cards.map((card, index) =>
                            <Fade key={index} right>
                                <li className={classes.item}>
                                    <img className={classes.check} src={check} alt='' />
                                    <div className={`${classes.itemContent} font-17`}>
                                        <h4 className={classes.subtitle}>
                                            <b>
                                                {t(card.title)}
                                            </b>
                                        </h4>
                                        <p className={classes.text}>
                                            {t(card.text)}
                                        </p>
                                    </div>
                                </li>
                            </Fade>
                        )}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default ChooseRight;

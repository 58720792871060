import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/HomePage/Banner/Banner";
import TakeAdvantages from "../components/HomePage/TakeAdvantages/TakeAdvantages";
import TradeSmarter from "../components/HomePage/TradeSmarter/TradeSmarter";
import Constructing from "../components/HomePage/Constructing/Constructing";
import Tabs from "../components/HomePage/Tabs/Tabs";
import Regulation from "../components/HomePage/Regulation/Regulation";
import OurAwards from "../components/HomePage/OurAwards/OurAwards";
import ReadyTo from "../components/HomePage/ReadyTo/ReadyTo";

const HomePage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Arma LTD</title>
        <meta name="title" content="Arma LTD Trading Platform - Your Gateway to Financial Markets" />
        <meta property="og:title" content="Arma LTD Trading Platform - Your Gateway to Financial Markets" />
        <meta property="twitter:title" content="Arma LTD Trading Platform - Your Gateway to Financial Markets" />

        <meta name="description" content="Explore Arma LTD, your premier trading platform, for access to diverse financial markets. Start trading stocks, forex, and more with confidence." />
        <meta property="og:description" content="Explore Arma LTD, your premier trading platform, for access to diverse financial markets. Start trading stocks, forex, and more with confidence." />
        <meta property="twitter:description" content="Explore Arma LTD, your premier trading platform, for access to diverse financial markets. Start trading stocks, forex, and more with confidence." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <TakeAdvantages />
        <TradeSmarter />
        <Constructing />
        <Tabs />
        <Regulation />
        <OurAwards />
        <ReadyTo />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default HomePage;

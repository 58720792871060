import React from "react";
import classes from './Tabs.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";

const Tab3 = ({ data }) => {
    const { t } = useTranslation();
    return (
        <div className={classes.tab_03}>
            <h2 className={`${classes.title} font-30`}>
                {t(data.title)}
            </h2>
            <div className={`${classes.gap30_15} font-17`}>
                <p>
                    {t(data.text_01)}
                </p>
                <p>
                    {t(data.text_02)}
                </p>
            </div>
            <div className="mt-btn">
                <Button />
            </div>
        </div>
    );
};

export default Tab3;

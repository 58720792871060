import React from "react";
import classes from './TradeSmarter.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/trade_smarter.avif';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const TradeSmarter = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.wrap}>
            <div className="mt container">
                <div className={classes.container}>
                    <h2 className={`${classes.title} font-30`}>
                        <Trans>
                            {t('home.trade_smarter.title')}
                        </Trans>
                    </h2>
                    <div className={`${classes.text} font-17 txt-container`}>
                        <p>
                            {t('home.trade_smarter.text_01')}
                        </p>
                        <p>
                            {t('home.trade_smarter.text_02')}
                        </p>
                    </div>
                    <div className="mt-btn">
                        <Button />
                    </div>
                </div>
            </div>
            <Fade right>
                <img className={classes.image} src={image} alt='' />
            </Fade>
        </section>
    );
};

export default TradeSmarter;

import React from "react";
import classes from './WhyOpen.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AccountsPage/why_open.avif';
import { Fade } from "react-reveal";

const WhyOpen = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-30`}>
                        {t('accounts.why_open.title')}
                    </h2>
                    <div className={`${classes.blocks} font-17`}>
                        <div className={`${classes.block} txt-container`}>
                            <h4>
                                <b>
                                    {t('accounts.why_open.block_01.subtitle')}
                                </b>
                            </h4>
                            <p className={classes.text}>
                                {t('accounts.why_open.block_01.text')}
                            </p>
                        </div>
                        <div className={`${classes.block} txt-container`}>
                            <h4>
                                <b>
                                    {t('accounts.why_open.block_01.subtitle')}
                                </b>
                            </h4>
                            <p className={classes.text}>
                                {t('accounts.why_open.block_01.text')}
                            </p>
                        </div>
                    </div>
                </div>
                <Fade right>
                <div className={classes.image}>
                    <img src={image} alt='' />
                </div>
                </Fade>
            </div>
        </section>
    );
};

export default WhyOpen;

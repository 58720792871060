import React from "react";
import classes from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/TradingPage/banner.avif';
import { Fade } from "react-reveal";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.wrap}>
            <div className="container">
                <div className={classes.container}>
                    <div className={classes.content}>
                        <Fade left>
                            <h1 className={`${classes.title} font-58`}>
                                <Trans>
                                    {t('trading.banner.title')}
                                </Trans>
                            </h1>
                        </Fade>
                        <Fade right>
                            <h2 className={`${classes.subtitle} font-35`}>
                                {t('trading.banner.subtitle')}
                            </h2>
                        </Fade>
                        <p className={`${classes.text} font-17`}>
                            {t('trading.banner.text')}
                        </p>
                    </div>
                    <Fade right>
                        <img className={classes.image} src={image} alt='' />
                    </Fade>
                </div>
            </div>
        </section>
    );
};

export default Banner;

import React from "react";
import classes from './Trade.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import check from '../../../assets/img/AccountsPage/list_check.svg';

const list = [
    'trading.cfd.trade.li_01',
    'trading.cfd.trade.li_02',
    'trading.cfd.trade.li_03',
    'trading.cfd.trade.li_04',
    'trading.cfd.trade.li_05',
    'trading.cfd.trade.li_06',
];

const Trade = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.head}>
                    <h2 className={`${classes.title} font-58`}>
                        <span>
                            {t('trading.cfd.trade.title')}
                        </span>
                    </h2>
                    <h4 className={`${classes.subtitle} font-30`}>
                        {t('trading.cfd.trade.subtitle')}
                    </h4>
                    <div className={classes.btn}>
                        <Button />
                    </div>
                </div>
                <ul className={`${classes.list} font-17`}>
                    {list.map((li, index) =>
                        <li key={index} className={classes.item}>
                            <img className={classes.check} src={check} alt='' />
                            <p className={classes.text}>
                                {t(li)}
                            </p>
                        </li>
                    )}
                </ul>
            </div>
        </section>
    );
};

export default Trade;

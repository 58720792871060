import React, { useEffect, useState } from "react";
import classes from './ChooseYour.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/TradingPage/choose_your.avif';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { Fade } from "react-reveal";

const cards = [
    {
        title: 'trading.choose_your.card_01.title',
        text: 'trading.choose_your.card_01.text',
    },
    {
        title: 'trading.choose_your.card_02.title',
        text: 'trading.choose_your.card_02.text',
    },
    {
        title: 'trading.choose_your.card_03.title',
        text: 'trading.choose_your.card_03.text',
    },
];

const ChooseYour = () => {
    const { t } = useTranslation();
    const [swiper, setSwiper] = useState({});
    const [active, setActive] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className="mt container">
            <div className={classes.wrap}>
                {windowWidth > 992 &&
                    <Fade left>
                        <div className={classes.image}>
                            <img src={image} alt='' />
                        </div>
                    </Fade>
                }
                <div className={classes.content}>
                    <div>
                        <h2 className={`${classes.title} font-30`}>
                            {t('trading.choose_your.title')}
                        </h2>
                        <p className={`${classes.text} font-17`}>
                            {t('trading.choose_your.text')}
                        </p>
                    </div>
                    <div>
                        <div className={classes.nav}>
                            <div
                                className={`${classes.navBtn} ${classes.left} ${active === 0 ? classes.inactive : ''}`}
                                onClick={() => swiper.slidePrev()}
                            >
                                <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M5 12H19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 5L19 12L12 19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div
                                className={`${classes.navBtn} ${active === swiper.snapGrid?.length - 1 ? classes.inactive : ''}`}
                                onClick={() => swiper.slideNext()}
                            >
                                <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M5 12H19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 5L19 12L12 19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className={classes.swiperWrap}>
                            <Swiper
                                modules={[Navigation]}
                                className="tradingChooseSwiper no-select"
                                grabCursor
                                spaceBetween={20}
                                slidesPerView={1}
                                onInit={(e) => { setSwiper(e) }}
                                onSlideChange={(e) => setActive(e.activeIndex)}
                                breakpoints={{
                                    1220: {
                                        slidesPerView: 2,
                                        spaceBetween: 70,
                                    },
                                    992: {
                                        slidesPerView: 1,
                                        spaceBetween: 70,
                                    },
                                    740: {
                                        slidesPerView: 2,
                                        spaceBetween: 40,
                                    },
                                }}
                            >
                                {cards.map((card, index) =>
                                    <SwiperSlide key={index} className="tradingChooseSwiperSlide">
                                        <div className={`
                                            ${classes.card} 
                                            ${!(index % 2) ? classes.light : classes.dark}    
                                            font-17
                                        `}>
                                            <h4 className={classes.cardTitle}>
                                                {t(card.title)}
                                            </h4>
                                            <p className={classes.cardText}>
                                                {t(card.text)}
                                            </p>
                                        </div>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ChooseYour;

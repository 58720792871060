import React from "react";
import classes from './Packed.module.scss';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import image from '../../../assets/img/CfdPage/packed.jpg';
import Button from "../../GlobalComponents/Button/Button";

const list = [
    {
        title: 'trading.cfd.packed.li_01.title',
        text: 'trading.cfd.packed.li_01.text',
    },
    {
        title: 'trading.cfd.packed.li_02.title',
        text: 'trading.cfd.packed.li_02.text',
    },
];

const Packed = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-30`}>
                        {t('trading.cfd.packed.title')}
                    </h2>
                    <ul className={classes.list}>
                        {list.map((li, index) =>
                            <Fade key={index} left>
                                <li className={classes.item}>
                                    <svg className={classes.check} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                        <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#B73D2B" />
                                    </svg>
                                    <div className={`${classes.itemContent} font-17`}>
                                        <h4 className={classes.subtitle}>
                                            <b>
                                                {t(li.title)}
                                            </b>
                                        </h4>
                                        <p className={classes.text}>
                                            {t(li.text)}
                                        </p>
                                    </div>
                                </li>
                            </Fade>
                        )}
                    </ul>
                    <div className={classes.btn}>
                        <Button />
                    </div>
                </div>
                <div className={classes.image}>
                    <img src={image} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Packed;